export default class Order {
  constructor (data = {}) {
    this.fillData(data)
  }

  setStatus (status) {
    if (status === 'processing') {
      return 'قيد المراجعة'
    } else if (status === 'publish') {
      return 'مكتمل'
    } else if (status === 'unpublish') {
      return 'غير منشور'
    } else if (status === 'completed') {
      return 'مكتمل'
    } else if (status === 'accepted') {
      return 'مقبول'
    } else if (status === 'denied') {
      return 'مرفوض'
    } else if (status === 'in_progress') {
      return 'قيد المراجعة'
    } else if (status === 'published') {
      return 'تم النشر'
    } else if (status === 'active') {
      return 'مفعل'
    } else {
      return 'معلق'
    }
  }

  fillData (data = {}) {
    this.id = data?.id || null
    this.order_num = data?.order_number || ''
    this.status = this.setStatus(data?.status || '')
    this.total_amount = data?.total_amount || 0
    this.added_value = data?.added_value || 0
    this.tax_amount = data?.tax_amount || 0
    this.shipping_amount = data?.delivery_cost || 0
    this.discount_amount = data?.discount_amount || 0
    this.sub_total = data?.sub_total_amount || 0
    this.created_at = data?.created_at_formatted || ''
    this.invoice = data?.invoice || null
    this.payment_method = data?.payment_method_type || ''
    this.order_products = data.orderItems
      ? data.orderItems.map((item) => new OrderProduct(item))
      : []
  }
}
export class OrderProduct {
  constructor (data = {}) {
    this.fillData(data)
  }

  fillData (data = {}) {
    this.id = data?.id || null
    this.name = data?.product?.title || ''
    this.featured_image = data?.product?.featured_image || ''
    this.product_category = data?.product?.category?.name || ''
    this.qty = data?.quantity || ''
    this.price = data?.total || ''
  }
}
