<template>
  <div>
    <dashboard-page-title :showMainActions="false">
    تفاصيل الطلب
    </dashboard-page-title>

    <div>
      <b-tabs content-class="mt-3">
        <b-tab :title="'معلومات الطلب'"   active>
          <div class="general-information">
            <b-row>
              <b-col md="6" class="mb-3">
                <input-form  v-model="order.order_num" :disabled="true"
                  placeholder="رقم الطلب" :label="`رقم الطلب`" />
              </b-col>
              <b-col md="6" class="mb-3">
                <input-form  :value="order.status" :disabled="true"
                  placeholder="حالة الطلب" :label="`حالة الطلب`" />
              </b-col>

              <b-col md="4" class="mb-3">
                <input-form  v-model="order.total_amount" :disabled="true"
                  placeholder="المبلغ الاجمالى" :label="`المبلغ الاجمالى`" />
              </b-col>
              <b-col md="4" class="mb-3">
                <input-form  v-model="order.tax_amount" :disabled="true"
                  placeholder="الضريبة" :label="`الضريبة`" />
              </b-col>
              <b-col md="4" class="mb-3">
                <input-form v-model="order.shipping_amount" :disabled="true"
                  placeholder="مصاريف الشحن" :label="`مصاريف الشحن`"
                />
              </b-col>
              <b-col md="4" class="mb-3">
                <input-form v-model="order.discount_amount" :disabled="true"
                  placeholder="الخصم" :label="`الخصم`"
                />
              </b-col>
              <b-col md="4" class="mb-3">
                <input-form  v-model="order.sub_total" :disabled="true"
                  placeholder="المبلغ الفرعى" :label="`المبلغ الفرعى`" />
              </b-col>

              <b-col md="6" class="mb-3">
                <input-form v-model="order.payment_method" :disabled="true"
                  placeholder="طريقة الدفع" :label="`طريقة الدفع`"
                />
              </b-col>
              <b-col md="6" class="mb-3">
                <input-form :placeholder="$t('main.created_at')" :label="$t('main.created_at')" v-model="order.created_at" :disabled="true"/>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab :title="'معلومات المنتج'" >
          <main-table :fields="fields" :items="order.order_products"></main-table>
        </b-tab>
      </b-tabs>
    </div>

    <!-- <pre class="text-left" dir="ltr">{{ order }}</pre> -->
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import marketPlaceServices from '../services/marketPlace'
import Order from '../model/Order'

export default {
  components: { },
  mounted () {
    core.index()
  },
  data () {
    return {
      id: this.$route.params.id,
      order: new Order(),
      fields: [
        { label: 'صورة المنتج', key: 'featured_image', type: 'image' },
        { label: 'اسم المنتج', key: 'name' },
        // { label: this.$t('products.categories.category'), key: 'product_category' },
        { label: 'التصنيفات', key: 'product_category' },
        // { label: this.$t('main.quantity'), key: 'qty' },
        { label: 'الكمية', key: 'qty' },
        // { label: this.$t('main.final_price'), key: 'price' }
        { label: 'السعر', key: 'price' }
      ]
    }
  },
  methods: {
    getOrder () {
      marketPlaceServices.getOrder(this.id).then(res => {
        this.order.fillData(res.data.data)
        console.log('order', this.order)
      }).catch(err => console.log('err', err))
    }
  },
  created () {
    this.getOrder()
  }
}
</script>
